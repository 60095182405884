.customer-input-group-override {
    flex-wrap: nowrap !important;
    height: 34px !important;
}

.serialnumber-input-group-override {
    flex-wrap: nowrap !important;
    height: 34px !important;
}

.customer-autocomplete-div {
    margin-top: -16px !important;
    height: 24px !important;
}

.serial-autocomplete-div {
    margin-top: -16px !important;
    height: 24px !important;
}

.customer-input-grouptext {
}

.serialnumber-input-grouptext {
}

.customer-input-textfield {
    height: 34px !important;
    flex-direction: row !important;
}

.serialnumber-input-textfield {
    height: 34px !important;
    flex-direction: row !important;
    margin-top: 0px !important;
}

.css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root {
    color: rgba(0, 0, 0, 0.6);
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.4375em;
    letter-spacing: 0.00938em;
    padding: 0;
    position: relative;
    display: block;
    transform-origin: top left;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: calc(100% - 24px);
    position: absolute;
    left: 0;
    top: 0;
    -webkit-transform: translate(14px, 8px) scale(1) !important;
    -moz-transform: translate(14px, 8px) scale(1) !important;
    -ms-transform: translate(14px, 8px) scale(1) !important;
    transform: translate(14px, 8px) scale(1) !important;
    -webkit-transition: color 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms, -webkit-transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms, max-width 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms;
    transition: color 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms, transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms, max-width 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms;
    z-index: 1;
    pointer-events: none;
}

.proposalform-searchbar-box {
    display: flex;
    gap: 16px;
    flex-direction: column;
}

.customer-textbox-field {
    flex: 1;
    width: 100%;
}

.device-textbox-field {
    flex: 1;
    width: 500px;
}

.status-textbox-field {
    flex: 1;
    width: 500px;
}

.date-select-field {
    flex: 1;
    width: 500px;
    margin-top: 16px;
}

.search-button-field {
    margin-top: 26px;
}

.search-results-box {
}

.body-box {
}

.defect-input-group-override {
    flex-wrap: nowrap !important;
    height: 100px !important;
}

.accessories-input-group-override {
    flex-wrap: nowrap !important;
    height: 100px !important;
}

.css-1kkal6p-MuiAutocomplete-root .MuiOutlinedInput-root .MuiAutocomplete-input {
    padding: 0px 4px 7.5px 5px !important;
}

.proposal-button {
    align-content: end;
    align-items: end;
    align-self: end;
}
