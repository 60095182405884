@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

body {
}

#left-div {
    height: 100vh;
    background-color: rgb(0 0 0 / 45%);
    padding: 7.5% 10%;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: space-between;
}

#left-div-logo img {
    max-width: 180px;
}

#left-div-parent {
    /*background-image: url(../assets/bg01.jpg);*/
    background-size: cover;
}

#left-div-heading-text {
    color: #f3f4f6;
}

    #left-div-heading-text h1 {
        font-size: 44px;
        margin-bottom: 15px;
    }

#left-div-disclaimer-text p {
    font-size: 10px;
    color: #fff;
}

#right-div {
    height: 100vh;
    display: flex;
    background: #f3f4f6;
    padding: 7.5% 10%;
}

#grid-container-1 {
    justify-content: center
}

#login-card-header {
    text-align: center;
    font-weight: 600;
    max-width: 350px;
}

    #login-card-header span {
        font-weight: 600;
        font-size: 28px;
        line-height: 1.2;
    }

#login-card-content-1 {
    max-width: 350px;
    font-size: 13px;
    color: #737373;
    text-align: center;
    line-height: 1.6;
    margin-top: -15px;
}

#input-email {
    background: #fff;
    padding: 12.5px 14px;
    border-radius: 4px;
}

#input-password {
    background: #fff;
    padding: 12.5px 14px;
    border-radius: 4px;
}

#card-container {
    max-height: 450px;
    max-width: 350px;
}

#login-submit-button {
    width: 100%;
    margin-top: 15px;
    box-shadow: none;
    background-color: #0d64ed;
    border-radius: 4px;
    text-transform: capitalize;
}

#login-accept-conditions-text p {
    text-align: center;
    font-size: 13px;
    color: #737373;
    margin-top: 30px;
}

#login-message {
    color: red;
    text-align: center;
    font-size: 13px;
}

@media screen and (max-width: 899px) {
    #left-div {
        height: 30vh;
        padding: 30px 45px;
    }

    #right-div {
        height: 70vh;
    }

    #left-div-logo {
        max-width: 160px;
    }

    #left-div-heading-text h1 {
        font-size: 32px;
        margin-bottom: 0;
    }

    #left-div-heading-text h5, #left-div-disclaimer-text, #login-card-content-1 {
        display: none;
    }

    #grid-container-1 {
        justify-content: center;
        align-items: flex-start;
    }

    #login-card-header {
        margin-bottom: -30px;
    }
}

#grid-container-user-details {
    padding: 5% 10%;
    display: flex !important;
    gap: 10px 30px;
    max-width: 980px;
    margin-left: auto;
    margin-right: auto;
}

#grid-container-user-details-1 {
    width: calc(50% - 15px);
}

#grid-container-user-details-2 {
}

#div-grid-parent {
    background: #f3f4f6;
    min-height: 100vh;
}

#grid-input-1 {
    background: #fff;
}

@media screen and (max-width: 899px) {
    #grid-container-user-details-1 {
        width: 100%;
    }
}

#create-user-headline img {
    max-width: 180px;
}

#create-user-headline h1 {
    font-weight: 600;
    font-size: 28px;
    line-height: 1.2;
    margin-top: 30px;
}

#create-user-headline h6 {
    color: #737373;
    line-height: 1.6;
}

#save-submit-grid-item {
    width: 100%;
}

#save-submit-button {
    width: 100%;
    background: #0d64ed;
    text-transform: capitalize;
}

#grid-container-payment-progress {
    display: flex !important;
    min-height: 100vh;
    flex-direction: column;
    align-items: center;
    padding: 5% 10%;
    background: #f3f4f6;
    justify-content: space-between;
}

#captchaBoxDescription p {
    font-size: 13px;
    color: #737373;
    text-align: center;
    line-height: 1.6;
    margin-top: -15px;
}

#card-header-progress-message {
    font-weight: 500;
    font-size: 28px;
    line-height: 1.2;
    text-align: center;
    margin-bottom: 30px;
    margin-top: 15px;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
}

#login-validation-button {
    width: 100%;
}

#phone-registration-validation-button {
    width: 100%;
}

#div-progress-parent {
    padding-bottom: 90px;
    max-width: 640px;
}

#input-validation {
    background: #fff;
    padding: 12.5px 14px;
    border-radius: 4px;
}

#linearprogress-box {
    margin-top: -30px;
}
