.status-input-group-override {
    flex-wrap: nowrap !important;
    height: 34px !important;
}

.status-autocomplete-div {
    margin-top: -16px !important;
    height: 24px !important;
}

.status-input-grouptext {
}

.status-input-textfield {
    height: 34px !important;
    flex-direction: row !important;
}

.employee-input-group-override {
    flex-wrap: nowrap !important;
    height: 34px !important;
}

.employee-autocomplete-div {
    margin-top: -16px !important;
    height: 24px !important;
}

.employee-input-grouptext {
}

.employee-input-textfield {
    height: 34px !important;
    flex-direction: row !important;
}

.input-group-text {
    display: flex;
    align-items: center;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    text-align: center;
    white-space: nowrap;
    background-color: #e9ecef;
    border: 1px solid #ced4da;
    border-radius: 0.375rem;
    min-width: 110px !important;
}

.bottom-buttons-box {
    display: flex;
    justify-content: center;
    flex-direction: row;
    margin-top: 20px;
    gap: 20px;
}

.css-1kkal6p-MuiAutocomplete-root .MuiOutlinedInput-root .MuiAutocomplete-input {
    padding: 0px 4px 7.5px 5px !important;
}
